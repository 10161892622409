.icon svg {
	width: 100px;
	height: 100px;
	fill: $oc-blue-6;
}


@media (max-width: 992px) {
.pb {
		padding-top: 50px;
	}
}

@media (max-width: 768px) {
.pb-np {
		padding-top: 0px;
	}
}
