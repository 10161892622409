.page-header.app-page {

  .content {
    padding-bottom: 30px;
  }

  .features-bg {
    padding: 30px 0;
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
  }

  .features-bg-blue {
    background-color: rgba(0, 149, 218, 0.75)!important;
  }

}

.page-header.app-page.vps-hosting-hero {
  .features-bg {
    background-color: rgba(0, 0, 0, 0.25);
  }
}


.page-header {
  min-height: 300px;
  background-image: linear-gradient(90deg, $oc-blue-6 0%, $oc-blue-8 100%);
  position: relative;
  overflow: hidden;
  text-shadow: 0 1px 1px rgba(0,0,0,.35);

  .content {
    padding-top: 100px;
    padding-bottom: 50px;
    margin: 0 auto;

    &.content-apps {
      padding-top: 20px;
    }

    @media(max-width: 991px) {
      padding-top: 70px;
      padding-bottom: 40px;
    }
    h1 {
      color: #fff;
      font-size: 50px;
      font-weight: 400;
      text-align: center;
      position: relative;
      z-index: 1;
      @media(max-width: 767px) {
        font-size: 32px;
      }
    }

    p {
      text-align: center;
      margin: 10px auto 0;
      font-size: 21px;
      //font-style: italic;
      font-weight: 300;
      color: #fff;
      opacity: 1;
      position: relative;
      z-index: 1;
      @media(max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  &.small-header {
    min-height: auto;
  }

  &.white-header {
     background: #fff;

    .content {
      padding-top: 70px;
      padding-bottom: 50px;
      margin: 0 auto;

      @media(max-width: 767px) {
        padding-top: 30px;
        padding-bottom: 40px;
      }
    }

    h1 {
      color: $oc-gray-8;
    }

    p {
      color: $oc-gray-6;
    }
  }

.vps-top-features {

  .pricing-charts {
    margin-top: 0;
    .chart {
      color: #fff;
      box-shadow: none;
      font-size: 17px;

      @media(max-width: 991px) {
        font-size: 16px;
      }

      @media(max-width: 767px) {
        font-size: 17px;
         margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
      }
    }
  }

  position: relative;
  padding-top: 0;
  margin-top: 0;

  opacity: 0.85;

  @media(max-width: 767px) {
    padding-top: 20px;
  }

  .header {
    text-align: center;
    width: 100%;
    margin: 0 0 10px;
    font-size: 25px;
    color: #fff;
    @media(max-width: 767px) {
      font-size: 20px;
    }
  }
  .topics {
    margin: 0;
    width: 100%;
    .topic {
      display: inline-block;
      float: left;
      text-decoration: none;
      width: 25%;
      text-align: left;
      padding: 5px 0;

      @include transition(all .2s);

      @media(max-width: 767px) {
        width: 50%;
        padding: 5px 0;
        text-align: left;
      }

      i {
        color: #fff;
        font-size: 16px;
        vertical-align: bottom;

        @media(max-width: 991px) {
          font-size: 18px;
        }
      }

      span {
        display: inline-block;
        margin-left: 1px;
        position: relative;
        top: 5px;
        color: #fff;
        font-size: 15px;

        @media(max-width: 991px) {
          font-size: 13px;
        }
      }
    }
  }
}

}

.section {
  padding: 50px 0;

  &-title {
    margin-bottom: 60px;
    text-align: center;

    @media(max-width: 767px) {
      margin-bottom: 45px;
    }

    h2{
      padding-top: 0;
      margin-top: 0;
      color: $oc-gray-8;
      font-size: 24px;
      letter-spacing: 2px;
      text-align: center;
      color: #4c4c4c;
      text-transform: uppercase;
      font-weight: 600;
      width: 100%;
      text-shadow: 1px 1px #fff;
    }

    h3 {
      color: $oc-gray-6;
      font-size: 20px;
      font-weight: 400;
    }

  }
}

.page-about, .page-reviews {
  .stack-image {
    transform: rotate(25deg);
    right: -100px;
    width: 40%;
    height: 40%;
    svg path {
      fill: $oc-blue-6;
    }
  }

  .stack-description {
    background: $oc-gray-1;
    color: $oc-gray-8;
  }

  .image svg {
    width: 192px;
    fill: $oc-blue-3;
  }

  .star-rating {
      font-size: 1.5em;
      margin-bottom: 0;
  }

}


.page-ssl-certificates {
  .stack-image {
    transform: rotate(25deg);
    right: -100px;
    width: 40%;
    height: 40%;
    svg path {
      fill: $oc-blue-6;
    }
  }

  #ssl-tabs a {
    font-size: 14px;
  }
  .icon svg {
    width: 74px;
    height: 74px;
  }
}


@media(min-width: 992px) {
  .page-ssl-certificates {
    .box{
      border-right: 1px solid $oc-gray-2;
    }
    .box-last {
      border-right: none;
    }
  }
}

.page-fully-managed {

  .fully-managed-main {
    .icon, svg {
      width: 64px;
      height: 64px;
    }
  }
  .p-bars {
    border-top: 1px solid $oc-gray-2;
    sup {
      font-size: 24px;
    }
  }
  .name {
      font-size: 15px;
  }


}

@media (max-width: 767.98px) {
  .page-fully-managed {
    .item {
      flex-direction: column;

      .name {
        max-width: 100%;
        text-align: left!important;

        br {
          display: none;
        }
      }
    }

    .title {
      margin-bottom: 30px;
    }
  }

}

.cloud-vps-hosting {

  .content {
    h1 {
      font-size: 64px;
      font-weight: bold;
    }
  }
  .features-4 {
    .icon {
      svg {
        width: 72px;
        height: 72px;
        fill: $oc-blue-6;
      }
    }
  }

  .vps-include ul li::before {
      content: "•";
      color: $oc-blue-6;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
  }


  .stacks-up .title{
      margin-bottom: 32px;
  }

  .stacks-up .title span{
      margin-left: 32px;
  }

  .stacks-up .title img{
       border-radius: 50%;
       width: 100px;
       height: 100px;
       margin-left: -120px;
  }

  .stacks-up .table-responsive{
      background: #fff;
      padding: 16px 16px 0;
  }

  .stacks-up .table-responsive th{
      font-size: 18px;
  }

  .table>thead>tr>th {
    border-bottom: 2px solid $oc-blue-6;
    border-top: 0;
  }

  .stacks-up .table-responsive th.rhc{
      color: $oc-blue-6;
  }

  .stacks-up .table-responsive td{
      font-size: 18px;
      padding: 20px;
  }

  .stacks-up .table-responsive td.rhc{
      background: $oc-blue-1;
      border-top-color: $oc-blue-1;
  }

  .stacks-up .plus{
     font-size: 15px;
      background: $oc-green-8;
      color: #fff;
      padding: 4px;
      border-radius: 2px;
  }

  .stacks-up .plus-grey{
      background: $oc-gray-6;
  }

  .stacks-up .minus{
      font-size: 15px;
      border: 1px solid $oc-gray-6;
      color: $oc-gray-6;
      padding: 4px;
      border-radius: 50%;
      width: 26px;
      height: 26px;
  }


  .logo-social img{
      text-align: center;
      margin: 0 auto 20px;
      display: block;
  }

  .logo-social .social-links i{
      font-size: 24px;
      color: $oc-blue-6;
  }
}

.page-website-migration {
  .stack-image {
    left:0;
    right: auto;
  }
  .second {
  }
  .third {
    padding-top: 0;
    h2 {
      text-transform: uppercase;
      color: $oc-violet-6;
      font-size:18px;
      font-weight: 600;
      letter-spacing:1px;
    }

    @media(max-width: 991px) {
      .box-nl {
        margin-bottom: 10px;
      }
    }

    .box{
      padding:15px;
      background:$oc-gray-1;
      height: 100%;
      border-radius: 5px;

      svg {
        width: 64px;
        height: 64px;
        .d1 {
          fill: $oc-violet-6;
        }
        .d2 {
          fill: $oc-violet-2;
        }
      }

    }
  }
}

.page-why-rosehosting  {

  .stack-image {
    svg path {
      fill: $oc-violet-6;
    }
  }

  section.why-items {
    // padding: 40px 0;

    svg {
      width: 100px;
      height: 100px;
    }
  }

}

.cloud-hosting {
  .screenshots {
    .title {
      margin-top: 20px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 600;
    }
    img {
      border: 1px solid #ddd;
      border-radius: 3px;
    }
  }
}


.page-contact {
  .section.contact-icons {padding-bottom: 20px}
  .contacts {
    margin-bottom: 40px;
    a{
      transition: all 0.5s ease;
      color: $oc-blue-8;
      display: block;
      &:hover {
        color: $oc-blue-5;
        svg {
          fill: $oc-blue-5;
        }
      }
    }
    svg {
      fill: $oc-blue-8;
      width: 64px;
      height: 64px;
    }
  }
}

.page-sale-finished {

    .why-items {
    fill: $oc-blue-8;
    h2 {font-size: 26px; }

    svg {
      width: 100px;
      height: 100px;
    }
  }

  .coupons {
    h2 {
      font-size: 26px;
    }

    .image{


     fill: #fff;
      svg {
        width: 110px;
        height: 110px;
        padding: 10px;
        margin: 10px;
        background: $oc-blue-7;
        border-radius: 50%;

      }

    }

  }
}

.genuitec {
  .page-header.white-header {
    background: $oc-blue-0;
  }

  .pricing-charts .chart > header .price {
    opacity: 1;
  }

  .image {
    svg {
      fill: $oc-blue-8;
      width: 64px;
      height:  64px;
    }
  }
  .sdc-feature {
    h4 {
      font-size:18px;
    }

    p {
      font-size: 16px;
    }
  }

}




.page-hosting-services  {
    .why-items {
    // padding: 40px 0;

    svg {
      width: 100px;
      height: 100px;
    }
  }
  .hosting-features {
   ul {
    float: left;
    width: 100%;
    li {
      float: left;
      width: 50%;
    }
   }
   .moreinfo {
    float: right;
    width: 100%;
    margin-top: 15px;
   }
  }
}

.page-services {
  .stack-image {
    transform: rotate(20deg);
    right: -100px;
    width: 30%;
    height: 30%;
    }
    svg path {
      fill: $oc-blue-6;
    }
  }

.page-monitoring {
  .stack-image {
    transform: rotate(20deg);
    right: -100px;
    width: 30%;
    height: 30%;
    svg path {
      fill: $oc-blue-6;
    }
  }

  .stack-description {
    background: $oc-gray-1;
    color: $oc-gray-8;
  }

  .image svg {
    width: 128px;
    height: 128px;
    fill: $oc-blue-3;
  }

}


.aff-buttons {
    @media(max-width: 600px) {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          margin-right: 0!important;
          margin-bottom: 10px;
          max-width:250px;
        }
    }
}

.page-affiliatefix {

  .page-header {
    padding: 100px 0 50px;
  }
  h1.affiliatefix {
     font-size:64px;
  }

  .card {
    .help {
      text-decoration: underline; // 2
      text-decoration: underline dotted; // 2
      cursor: help; // 3
      border-bottom: 0; // 1
    }
    p {
      margin-bottom: 5px;
    }
  }

}

.page-vip-affiliate {
  .page-header{
    text-shadow: none;

    .stack-image svg {
    transform: rotate(25deg);
        right: -100px;
        fill: $oc-blue-2;
    }
    .content {
        h1, p {
          opacity: 1;
          color: $oc-gray-8;
        }
    }
  }

  .icon {
    text-align: center;
    svg {
     width: 128px;
     height: 128px
    }
  }
}

.hosting-by-type {

  .page-header {

  }

  .items.section {
    padding-bottom: 0;

    .row > div {
      padding-bottom: 60px;
    }

    h3 {
      border-bottom: 1px solid #343434;
      display: inline-block;
    }
  }
  .image img {
    width: auto;
    height: 83px;
  }

  .image svg, {
    height: 75px;
  }

  .odoo svg {
    fill: #A24689;
  }

  &.hosting-by-type-all {
    .type-title {
      margin-bottom: 30px;
      text-align: center;
      font-size: 24px;
      text-transform: uppercase;
      color: $oc-blue-9;
    }
    .image img {
      width: auto;
      height: 63px;
    }

    .image svg, {
      height: 55px;
    }

    .btn-shadow.btn-shadow-sm {
        font-size: 10px;
        padding: 7px 14px;
    }
  }
}

.csr-generator {
  #postgenerate {
    display: none;
  }
}

.network-tools {
   .card {
    .card-body {
      position: relative;
    }
    .card-footer {
      z-index: 1;

    }
     .image {
      position: absolute;
      top: 0;
      left: 0;
      opacity: .1;
       svg {
        width: 192px;
        height: 192px;
        fill: $oc-blue-6;
       }
     }

   }

}
