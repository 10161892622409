.pricing-charts-tabs {
  margin-top: 30px;
  text-align: center;

  .tabs {
    margin: 0 auto;
    width: 330px;
  }

  .tab {
    display: inline-block;
    width: 165px;
    float: left;
    border: 1px solid #c7c7c7;
    padding: 13px 0;
    background: #f9f9f9;
    color: #8F8B8B;
    cursor: pointer;
    position: relative;
    font-size: 16px;

    @include transition(color .2s linear);

    &:hover {
      color: #222;
    }

    &.active {
      background: #fff;
      color: #222;
      font-weight: 600;
      cursor: default;

      &:hover {
        color: #222;
      }
    }

    &.monthly {
      border-radius: 45px 0 0 45px;

      span {
        position: relative;
        left: 5px;
      }
    }

    &.annual {
      border-left: 0;
      border-radius: 0 45px 45px 0;

      span {
        position: relative;
        left: -5px;
      }
    }

    .discount {
      position: absolute;
      top: -10px;
      font-size: 13px;
    }
  }
}


.pricing-charts {
  margin-top: 30px;


  &.high-end{
    .chart {

      &:nth-child(1) > header {
        background-image: linear-gradient(45deg, $oc-violet-5 50%, $oc-violet-6 100%);
      }
      &:nth-child(2) > header {
        background-image: linear-gradient(45deg, $oc-violet-6 50%, $oc-violet-7 100%);
      }
      &:nth-child(3) > header {
        background-image: linear-gradient(45deg, $oc-violet-7 50%, $oc-violet-8 100%);
      }
      &:nth-child(4) > header {
        background-image: linear-gradient(45deg, $oc-violet-8 50%, $oc-violet-9 100%);
      }
      &:nth-child(5) > header {
        background-image: linear-gradient(45deg, $oc-violet-9 50%, $oc-violet-9 100%);
      }

      .btn-shadow.btn-shadow-primary {
        background: linear-gradient($oc-violet-8, $oc-violet-9);
      }

    }
  }

  .chart {
    .mask {
      position: absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
      z-index:2;
      opacity: .5;
      background-color: #000;
    }
    // border: 1px solid #d6d6d6;
    width: 275px;
    display: inline-block;
    border-radius: 4px;
    margin: 0 10px;
    box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
    position:relative;

      &:nth-child(1) > header {
        background-image: linear-gradient(45deg, $oc-blue-5 50%, $oc-blue-6 100%);
      }
      &:nth-child(2) > header {
        background-image: linear-gradient(45deg, $oc-blue-6 50%, $oc-blue-7 100%);
      }
      &:nth-child(3) > header {
        background-image: linear-gradient(45deg, $oc-blue-7 50%, $oc-blue-8 100%);
      }
      &:nth-child(4) > header {
        background-image: linear-gradient(45deg, $oc-blue-8 50%, $oc-blue-9 100%);
      }
      &:nth-child(5) > header {
        background-image: linear-gradient(45deg, $oc-blue-9 50%, $oc-blue-9 100%);
      }
    .btn-shadow.btn-shadow-primary {
      background: linear-gradient($oc-blue-8, $oc-blue-9);
    }

    .btn-shadow.btn-shadow-cpanel {
      background: linear-gradient($oc-orange-6, $oc-orange-6);
    }

    .btn-shadow.btn-shadow-da {
      background: linear-gradient($oc-blue-6, $oc-blue-6);
    }

    @media(max-width: 991px) {
      width: 240px;
      margin: 0 10px;
      display: block;
    }

    @media(max-width: 767px) {
      display: block;
      width: 290px;
      margin: 0 auto 30px;
    }

    > header {
      // background: #f8f8f8;
      // border-bottom: 1px solid #d6d6d6;
      padding: 20px 0 17px;
      height: 140px;
      position: relative;
      text-shadow: 0px 1px 2px rgba(62,57,107,0.4);
      color: #fff;
      border-radius: 4px 4px 0 0;

      @media(max-width: 991px) {
        height: 175px;
      }

      .name, .name h2 {
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        // color: #555;
        letter-spacing: 1px;
      }

      .price {
        // padding-top: 5px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        .price-value{
          right:  -17px;
          position: relative;
        }

        @include transition(all .25s linear);

        &.monthly {
          @include transform(translateY(25px));

          &.active {
            opacity: 1;
            @include transform(translateY(0px));
          }

          &.go-out {
            opacity: 0;
            @include transform(translateY(25px));
          }
        }

        &.annually {
          @include transform(translateY(-25px));

          &.active {
            opacity: 1;
            @include transform(translateY(0px));
          }

          &.go-out {
            opacity: 0;
            @include transform(translateY(-25px));
          }
        }

        .currency {
          font-size: 24px;
          position: relative;
          top: -0.75rem;
          // color: #444;
          display: inline-block;
        }

        .qty {
          font-size: 48px;
          font-weight: 400;
          display: inline-block;
          line-height: 48px;
          @media(max-width: 991px) {
            font-size: 48px;
          }

          sup {
            font-size: 18px;
            top: -1.25rem;
          }
        }

        .was {
          opacity: .5;

          &.no-opacity {
            opacity: 1;
          }
        }

        .period {
          font-size: 14px;
          // color: #555;
          position: relative;
          top: -1px;
          display: inline-block;
          left:  -1.75rem;
        }
      }
    }

    .features {
      // min-height: 320px;
      padding: 20px 0 16px;

      .feature {
        text-align: center;
        font-size: 16px;
        padding: 0 0 5px 0;
        color: #555;

        .reduce-gutter {
            padding: 0 5px;
        }
        .key {
          text-align: left;
        }
        .value {
          text-align: right;
        }
        @media(max-width: 991px) {
          font-size: 15px;
        }
      }
    }

    .action {
      text-align: center;
      padding: 0 0 24px;
      a {

        &.monthly, &.annually {
          display: none;

          &.active {
            display: inline-block;
          }

          &.go-out {
            display: none;

          }
        }
      }
    }
  }
}


.pricing-features {
   background: $oc-gray-1;

  @media(max-width: 767px) {
    .section-title {
        margin-bottom: 5px;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 400;
  }

  .features {
    margin: auto;
    width: 92%;

    @media(max-width: 991px) {
      width: auto;
    }

    svg, .icon {
      width: 48px;
      height: 48px;
      fill: $oc-blue-8;
      @media(max-width: 767px) {
        display: block;
        margin: 0 auto;
      }
    }

    .feature {
      margin-top: 30px;

      @media(max-width: 767px) {
        margin-top: 40px;

        &:first-child {
           margin-top: 40px;
        }
      }
    }

    section {
      float: right;
      width: 83%;

      @media(max-width: 991px) {
        width: 80%;
      }

      @media(max-width: 767px) {
        float: none;
        width: auto;
        margin-top: 10px;
        text-align: center;
      }

      h4 {
        margin-top: 0;
        font-size: 18px;
        color: #53575f;
      }

      p {
        margin: 5px 0 0 0;
        font-size: 16px;
        line-height: 24px;
        color: $oc-gray-7;
        width: 95%;

        @media(max-width: 767px) {
          width: auto;
        }
      }
    }
  }
}





.page-monitoring {
   .period {
    left:0!important;
  }
  .free {
    font-size: 40px;
    margin-right: 17px!important;
  }
  .price-value {
    margin-right: 17px!important;
  }
}


.other-plans {

  .title {
    font-size: 22px;
    text-transform: uppercase;
  }

  // table {
  //   table-layout: fixed;
  // }

  thead th {
    background: #f1f3f5;
    font-size: 14px;
    text-transform: uppercase;
        text-align: center;
    border-bottom: none;
  }

  .table td, .table th {
    font-size: 14px;
        text-align: center;
  }

.btn-shadow.btn-shadow-sm {
    font-size: 10px;
    padding: 7px 7px;
}

  .pricing-charts .chart {
      width: 100%;
      display: inline-block;
      border-radius: 0;
      margin: 0 ;
      box-shadow: none;
      position: relative;
  }

  .price, .action a{
    display: none;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }
  .price.active, .action a.active {
    display: inline-block;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  }

}