.star-rating {
    display: flex;
    align-items: center;
    font-size: 2.15em;
    justify-content: center;
    margin-bottom: 20px;

    .reviews & {
        font-size: 1.5em;
        margin-bottom: 0;
    }
}
.back-stars {
    display: flex;
    color: #bcbcbc;
    position: relative;
    margin-left: 10px;
}

.front-stars {
    display: flex;
    color: gold;
    overflow: hidden;
    position: absolute;
    top: 0;
}

.back-stars, .front-stars {
    .fa-star{
      margin-right: 10px;
     &.last {
      margin-right: 0;
     }
    }
}
