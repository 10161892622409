$ubuntu-aubergine-0: #77216F;
$ubuntu-aubergine-1: #5E2750;
$ubuntu-aubergine-2: #2C001E;
$ubuntu-orange-0: #E95420;
$ubuntu-orange-1: darken($ubuntu-orange-0,10%);

$debian-violet-1: #a80030;
$debian-violet-0: lighten($debian-violet-1,5%);
$debian-violet-2: darken($debian-violet-1,5%);
$debian-red-0: #CA005D;
$debian-red-1: darken($debian-red-0,10%);

$archlinux-blue-1: #1793d1;
$archlinux-blue-0: lighten($archlinux-blue-1,5%);
$archlinux-blue-2: darken($archlinux-blue-1,5%);
$archlinux-black-0: #333;
$archlinux-black-1: darken($archlinux-black-0,10%);

$opensuse-green-0: #73ba25;
$opensuse-green-1: #96cb53;
$opensuse-green-2: darken($opensuse-green-0,5%);
$opensuse-green1-0: #1aad95;
$opensuse-green1-1: #40bba7;

$centos-violet-1: #932279;
$centos-violet-0: lighten($centos-violet-1,5%);
$centos-violet-2: darken($centos-violet-1,5%);
$centos-orange-0: #efa724;
$centos-orange-1: darken($centos-orange-0,10%);

$laravel: #f55247;
$ghost: #9fbb58;
$magento: #f46f25;
$woocommerce: #B682AB;
$zencart: #D35A32;
$typesettercms: #2C3E50;
$concrete5: #34b0f6;
$craftcms: #DA513D;
$git: #4e443c;
$meteor: #e04e4b;
$python: #2b5b84;
$subversion: #93b0da;
$odoo: #875a7b;
$mongodb: #69b241;
$wordpress: #0073aa;
$cpanel: #ff6c2c;
$webmin: #3B73CA;
$codeigniter: #677e84;
$civicrm: #64A13F;
$virtualmin: #3B73CA;
$mysql: #F29111;
$mariadb: #313a43;
$tomcat: #D1A41A;
$teamspeak: #445277;
$nodejs: #339933;
$cloudflare: #f48120;
$django: #0C3C26;
$owncloud: #428bca;
$nextcloud: #428bca;
$fourimages: #2B4D73;
$apache: #D12127;
$apache-1: #F49422;
$apache-2: #DB502C;
$bugzilla: #98629A;
$bugzilla-1: #ac5aaf;
$bugzilla-2: #956097;
$cakephp: #D33C43;
$chamilo: #2E75A3;
$cs-cart: #0bccff;
$drupal: #0678BE;
$elasticsearch: #005571;
$hhvm: #505050;
$suitecrm: #5C566A;
$sugarcrm: #41b7d1;
$pbx: #1b6ec2;
$roundcube: #228ae6;
$postgresql: #0085B0;
$redmine: #C51F05;
$glassfish: #1c6f8b;
$elastix: #EE2E24;
$directadmin: #0083B4;
$jboss: #CC0000;
$grails: #FEB672;
$ispconfig: #CC141B;
$rails: #cc0000;
$vbulletin: #184d66;
$prestashop: #b20455;
$nginx: #009639;
$redis: #C6302B;
$phpmyadmin: #337ab7;
$opencart: #0080C3;
$vestacp: #f79b44;
$lemp: #506877;
$lamp: #a01e8a;
$joomla: #5091cd;
$vtiger: #5cb85c;
$php: #337ab7;
$metasfresh: #76B727;

// APPS
.page-header,
.pricing-charts .chart > header,
.pricing-charts .chart .btn-shadow.btn-shadow-primary,
.other-plans .btn-shadow.btn-shadow-primary,
.stack-description{ 
  .ubuntu & { background-image: linear-gradient(45deg, $ubuntu-aubergine-0 0%, $ubuntu-aubergine-1 100%); }
  .debian & { background-image: linear-gradient(45deg, $debian-violet-0 0%, $debian-violet-1 100%); }
  .centos & { background-image: linear-gradient(45deg, $centos-violet-0 0%, $centos-violet-1 100%); }
  .archlinux & { background-image: linear-gradient(45deg, $archlinux-blue-0 0%, $archlinux-blue-1 100%); }
  .opensuse & { background-image: linear-gradient(45deg, $opensuse-green-0 0%, $opensuse-green-1 100%); }

  .laravel & { background-image: linear-gradient(45deg, $laravel 0%, darken($laravel,5%) 100%) !important; }
  .ghost & { background-image: linear-gradient(45deg, $ghost 0%, darken($ghost,5%) 100%) !important; }
  .magento & { background-image: linear-gradient(45deg, $magento 0%, darken($magento,5%) 100%) !important; }
  .hhvm & { background-image: linear-gradient(45deg, $hhvm 0%, darken($hhvm,5%) 100%) !important; }
  .bugzilla & { background-image: linear-gradient(45deg, $bugzilla 0%, darken($bugzilla,5%) 100%) !important; }
  .glassfish & { background-image: linear-gradient(45deg, $glassfish 0%, darken($glassfish,5%) 100%) !important; }
  .directadmin & { background-image: linear-gradient(45deg, $directadmin 0%, darken($directadmin,5%) 100%) !important; }
  .jboss & { background-image: linear-gradient(45deg, $jboss 0%, darken($jboss,5%) 100%) !important; }
  .grails & { background-image: linear-gradient(45deg, $grails 0%, darken($grails,5%) 100%) !important; }
  .ispconfig & { background-image: linear-gradient(45deg, $ispconfig 0%, darken($ispconfig,5%) 100%) !important; }
  .rails & { background-image: linear-gradient(45deg, $rails 0%, darken($rails,5%) 100%) !important; }
  .vbulletin & { background-image: linear-gradient(45deg, $vbulletin 0%, darken($vbulletin,5%) 100%) !important; }

  .woocommerce & { background-image: linear-gradient(45deg, $woocommerce 0%, darken($woocommerce,5%) 100%) !important; }
  .drupal & { background-image: linear-gradient(45deg, $drupal 0%, darken($drupal,5%) 100%) !important; }
  .elasticsearch & { background-image: linear-gradient(45deg, $elasticsearch 0%, darken($elasticsearch,5%) 100%) !important; }
  .elastix & { background-image: linear-gradient(45deg, $elastix 0%, darken($elastix,5%) 100%) !important; }
  .fourimages & { background-image: linear-gradient(45deg, $fourimages 0%, darken($fourimages,5%) 100%) !important; }
  .cs-cart & { background-image: linear-gradient(45deg, $cs-cart 0%, darken($cs-cart,5%) 100%) !important; }
  .apache & { background-image: linear-gradient(5deg, $apache 0%, $apache-1 100%) !important; }
  .cakephp & { background-image: linear-gradient(45deg, $cakephp 0%, darken($cakephp,5%) 100%) !important; }
  .chamilo & { background-image: linear-gradient(45deg, $chamilo 0%, darken($chamilo,5%) 100%) !important; }
  .zencart & { background-image: linear-gradient(45deg, $zencart 0%, darken($zencart,5%) 100%) !important; }
  .typesettercms & { background-image: linear-gradient(45deg, $typesettercms 0%, darken($typesettercms,5%) 100%) !important; }
  .concrete5 & { background-image: linear-gradient(45deg, $concrete5 0%, darken($concrete5,5%) 100%) !important; }
  .craftcms & { background-image: linear-gradient(45deg, $craftcms 0%, darken($craftcms,5%) 100%) !important; }
  .git & { background-image: linear-gradient(45deg, $git 0%, darken($git,5%) 100%) !important; }
  .meteor & { background-image: linear-gradient(45deg, $meteor 0%, darken($meteor,5%) 100%) !important; }
  .python & { background-image: linear-gradient(45deg, $python 0%, darken($python,5%) 100%) !important; }
  .subversion & { background-image: linear-gradient(45deg, $subversion 0%, darken($subversion,5%) 100%) !important; }
  .odoo & { background-image: linear-gradient(45deg, $odoo 0%, darken($odoo,5%) 100%) !important; }
  .mongodb & { background-image: linear-gradient(45deg, $mongodb 0%, darken($mongodb,5%) 100%) !important; }
  .wordpress & { background-image: linear-gradient(45deg, $wordpress 0%, darken($wordpress,5%) 100%) !important; }
  .cpanel & { background-image: linear-gradient(45deg, $cpanel 0%, darken($cpanel,5%) 100%) !important; }
  .webmin & { background-image: linear-gradient(45deg, $webmin 0%, darken($webmin,5%) 100%) !important; }
  .codeigniter & { background-image: linear-gradient(45deg, $codeigniter 0%, darken($codeigniter,5%) 100%) !important; }
  .civicrm & { background-image: linear-gradient(45deg, $civicrm 0%, darken($civicrm,5%) 100%) !important; }
  .virtualmin & { background-image: linear-gradient(45deg, $virtualmin 0%, darken($virtualmin,5%) 100%) !important; }
  .mysql & { background-image: linear-gradient(45deg, $mysql 0%, darken($mysql,5%) 100%) !important; }
  .mariadb & { background-image: linear-gradient(45deg, $mariadb 0%, darken($mariadb,5%) 100%) !important; }
  .tomcat & { background-image: linear-gradient(45deg, $tomcat 0%, darken($tomcat,5%) 100%) !important; }
  .teamspeak & { background-image: linear-gradient(45deg, $teamspeak 0%, darken($teamspeak,5%) 100%) !important; }
  .nodejs & { background-image: linear-gradient(45deg, $nodejs 0%, darken($nodejs,5%) 100%) !important; }
  .cloudflare & { background-image: linear-gradient(45deg, $cloudflare 0%, darken($cloudflare,5%) 100%) !important; }
  .django & { background-image: linear-gradient(45deg, $django 0%, darken($django,5%) 100%) !important; }
  .owncloud & { background-image: linear-gradient(45deg, $owncloud 0%, darken($owncloud,5%) 100%) !important; }
  .nextcloud & { background-image: linear-gradient(45deg, $nextcloud 0%, darken($nextcloud,5%) 100%) !important; }
  .suitecrm & { background-image: linear-gradient(45deg, $suitecrm 0%, darken($suitecrm,5%) 100%) !important; }
  .sugarcrm & { background-image: linear-gradient(45deg, $sugarcrm 0%, darken($sugarcrm,5%) 100%) !important; }
  .pbx & { background-image: linear-gradient(45deg, $pbx 0%, darken($pbx,5%) 100%) !important; }
  .roundcube & { background-image: linear-gradient(45deg, $roundcube 0%, darken($roundcube,5%) 100%) !important; }
  .postgresql & { background-image: linear-gradient(45deg, $postgresql 0%, darken($postgresql,5%) 100%) !important; }
  .redmine & { background-image: linear-gradient(45deg, $redmine 0%, darken($redmine,5%) 100%) !important; }
  .prestashop & { background-image: linear-gradient(45deg, $prestashop 0%, darken($prestashop,5%) 100%) !important; }
  .nginx & { background-image: linear-gradient(45deg, $nginx 0%, darken($nginx,5%) 100%) !important; }
  .redis & { background-image: linear-gradient(45deg, $redis 0%, darken($redis,5%) 100%) !important; }
  .phpmyadmin & { background-image: linear-gradient(45deg, $phpmyadmin 0%, darken($phpmyadmin,5%) 100%) !important; }
  .opencart & { background-image: linear-gradient(45deg, $opencart 0%, darken($opencart,5%) 100%) !important; }
  .vestacp & { background-image: linear-gradient(45deg, $vestacp 0%, darken($vestacp,5%) 100%) !important; }
  .lemp & { background-image: linear-gradient(45deg, $lemp 0%, darken($lemp,5%) 100%) !important; }
  .lamp & { background-image: linear-gradient(45deg, $lamp 0%, darken($lamp,5%) 100%) !important; }
  .joomla & { background-image: linear-gradient(45deg, $joomla 0%, darken($joomla,5%) 100%) !important; }
  .metasfresh & { background-image: linear-gradient(45deg, $metasfresh 0%, $metasfresh 100%) !important; }
  .vtiger & { background-image: linear-gradient(45deg, $vtiger 0%, darken($vtiger,5%) 100%) !important; }
  .php & { background-image: linear-gradient(45deg, $php 0%, darken($php,5%) 100%) !important; }
}

.stack-image {
  .laravel & { left: -52px; svg {fill: lighten($laravel,5%);}}
  .ghost & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($ghost,5%);}}
  .fourimages & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($fourimages,5%);}}
  .apache & { left: 32px; top:150px; opacity: .25; transform: rotate(60deg);  svg {fill: lighten($apache,55%);}}
  .cakephp & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($cakephp,5%);}}
  .directadmin & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($directadmin,5%);}}
  .jboss & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($jboss,5%);}}
  .grails & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($grails,5%);}}
  .ispconfig & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($ispconfig,5%);}}
  .rails & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($rails,5%);}}
  .vbulletin & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($vbulletin,5%);}}

  .cs-cart & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($cs-cart,5%);}}
  .drupal & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($drupal,5%);}}
  .hhvm & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($hhvm,5%);}}
  .glassfish & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($glassfish,5%);}}
  .elastix & { left: 32px; top:50px;  transform: rotate(30deg);  svg {fill: lighten($elastix,5%);}}
  .elasticsearch & { left: 32px; top:50px;  transform: rotate(60deg);  svg {fill: lighten($elasticsearch,5%);}}
  .magento & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($magento,5%);}}
  .bugzilla & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($bugzilla,5%);}}
  .woocommerce & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($woocommerce,5%);}}
  .chamilo & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($chamilo,5%);}}
  .zencart & { left: -67px;  transform: rotate(-25deg);  svg {fill: lighten($zencart,5%);}}
  .typesettercms & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($typesettercms,5%);}}
  .concrete5 & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($concrete5,5%);}}
  .craftcms & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($craftcms,5%);}}
  .git & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($git,5%);}}
  .meteor & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($meteor,5%);}}
  .python & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($python,5%);}}
  .subversion & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($subversion,5%);}}
  .odoo & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($odoo,5%);}}
  .mongodb & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($mongodb,5%);}}
  .wordpress & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($wordpress,5%);}}
  .cpanel & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($cpanel,5%);}}
  .webmin & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($webmin,5%);}}
  .codeigniter & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($codeigniter,5%);}}
  .civicrm & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($civicrm,5%);}}
  .virtualmin & { left: 32px;  transform: rotate(25deg);  svg {fill: lighten($virtualmin,5%);}}
  .mysql & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($mysql,5%);}}
  .mariadb & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($mariadb,5%);}}
  .tomcat & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($tomcat,5%);}}
  .teamspeak & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($teamspeak,5%);}}
  .nodejs & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($nodejs,5%);}}
  .suitecrm & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($suitecrm,5%);}}
  .sugarcrm & { left: 32px;  transform: rotate(-25deg);  svg {fill: lighten($sugarcrm,5%);}}
  .cloudflare & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($cloudflare,5%);}}
  .django & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($django,5%);}}
  .owncloud & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($owncloud,5%);}}
  .nextcloud & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($nextcloud,5%);}}
  .pbx & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($pbx,5%);}}
  .roundcube & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($roundcube,5%);}}
  .postgresql & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($postgresql,5%);}}
  .redmine & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($redmine,5%);}}
  .prestashop & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($prestashop,5%);}}
  .nginx & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($nginx,5%);}}
  .redis & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($redis,5%);}}
  .phpmyadmin & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($phpmyadmin,5%);}}
  .opencart & { left: -62px;  transform: rotate(-15deg);  svg {fill: lighten($opencart,5%);}}
  .vestacp & { left: -62px;  transform: rotate(-15deg);  svg {fill: lighten($vestacp,5%);}}
  .lemp & { left: -62px;  transform: rotate(-15deg);  svg {fill: lighten($lemp,5%);}}
  .lamp & { left: 32px; top:150px;  transform: rotate(60deg);  svg {fill: lighten($lamp,5%);}}
  .joomla & { left: 32px; top:150px;  transform: rotate(60deg);  svg {fill: lighten($joomla,5%);}}
  .metasfresh & { left: 32px; top:150px;  transform: rotate(60deg);  svg {fill: lighten($metasfresh,5%);}}
  .vtiger & { left: 32px; transform: rotate(-15deg);  svg {fill: lighten($vtiger,5%);}}
  .php & { left: 32px;  transform: rotate(-15deg);  svg {fill: lighten($php,5%);}}
}


// DISTROS

.stack-image {
    position: absolute;
    width: 50%;
    height: 50%;
    z-index: 0;
    top: 0;
    svg {fill: $oc-blue-6;}
   .ubuntu & svg {fill: $ubuntu-aubergine-0;}
   .debian & svg {fill: $debian-violet-0;}
   .centos & svg {fill: $centos-violet-0;}
   .archlinux & svg {fill: $archlinux-blue-0;}
   .opensuse & svg {fill: $opensuse-green-0; width: 800px; height: 600px;}

}

.page-header {
  .ubuntu & { background-image: linear-gradient(90deg, $ubuntu-aubergine-0 0%, $ubuntu-aubergine-1 100%);}
  .debian & { background-image: linear-gradient(90deg, $debian-violet-0 0%, $debian-violet-1 100%);}
  .centos & { background-image: linear-gradient(90deg, $centos-violet-0 0%, $centos-violet-1 100%);}
  .archlinux & { background-image: linear-gradient(90deg, $archlinux-blue-0 0%, $archlinux-blue-1 100%);}
  .opensuse & { background-image: linear-gradient(90deg, $opensuse-green-0 0%, $opensuse-green-1 100%);}
}

.pricing-charts .chart > header {

    .ubuntu & { background-image: linear-gradient(45deg, $ubuntu-aubergine-0 50%, $ubuntu-aubergine-1 100%) !important;}
    .debian & { background-image: linear-gradient(45deg, $debian-violet-0 50%, $debian-violet-1 100%) !important;}
    .centos & { background-image: linear-gradient(45deg, $centos-violet-0 50%, $centos-violet-1 100%) !important;}
    .archlinux & { background-image: linear-gradient(45deg, $archlinux-blue-0 50%, $archlinux-blue-1 100%) !important;}
    .opensuse & { background-image: linear-gradient(45deg, $opensuse-green-0 50%, $opensuse-green-1 100%) !important;}
}

.pricing-charts.high-end .chart > header {
    .ubuntu & { background-image: linear-gradient(45deg, $ubuntu-aubergine-1 50%, $ubuntu-aubergine-2 100%) !important;}
    .debian & { background-image: linear-gradient(45deg, $debian-violet-1 50%, $debian-violet-2 100%) !important;}
    .centos & { background-image: linear-gradient(45deg, $centos-violet-1 50%, $centos-violet-2 100%) !important;}
    .archlinux & { background-image: linear-gradient(45deg, $archlinux-blue-1 50%, $archlinux-blue-2 100%) !important;}
    .opensuse & { background-image: linear-gradient(45deg, $opensuse-green-1 50%, $opensuse-green-2 100%) !important;}
}

.pricing-charts .chart .btn-shadow.btn-shadow-primary {


    .ubuntu & { background: linear-gradient($ubuntu-aubergine-0, $ubuntu-aubergine-1);}
    .debian & { background: linear-gradient($debian-violet-0, $debian-violet-1);}
    .centos & { background: linear-gradient($centos-violet-0, $centos-violet-1);}
    .archlinux & { background: linear-gradient($archlinux-blue-0, $archlinux-blue-1);}
    .opensuse & { background: linear-gradient($opensuse-green-0, $opensuse-green-1);}
}

.pricing-charts.high-end .chart .btn-shadow.btn-shadow-primary {
    .ubuntu & { background: linear-gradient($ubuntu-aubergine-1, $ubuntu-aubergine-2);}
    .debian & { background: linear-gradient($debian-violet-1, $debian-violet-2);}
    .centos & { background: linear-gradient($centos-violet-1, $centos-violet-2);}
    .archlinux & { background: linear-gradient($archlinux-blue-1, $archlinux-blue-2);}
    .opensuse & { background: linear-gradient($opensuse-green-1, $opensuse-green-2);}
}



// .vps-testimonials {
//     .ubuntu & { background-image: linear-gradient(90deg, $ubuntu-aubergine-1, 0, $ubuntu-aubergine-2)!important;}
//     .debian & { background-image: linear-gradient(90deg, $debian-violet-1, 0, $debian-violet-2)!important;}
//     .archlinux & { background-image: linear-gradient(90deg, $archlinux-blue-1, 0, $archlinux-blue-2)!important;}
//     .opensuse & { background-image: linear-gradient(90deg, $opensuse-green-1, 0, $opensuse-green-2)!important;}
// }

.stack-description {
    padding: 90px 0;
    color: #fff;
    background-image: linear-gradient(90deg, $oc-blue-6 0%, $oc-blue-8 100%);
    .title {
      font-size: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: 400;
    }
    p {
      font-weight: 300;
    }
    .image svg {
        width: 192px;
        fill: #fff;
    }

    .ubuntu & a.create {
      color: $ubuntu-orange-0;
      &:hover { color: $ubuntu-orange-1;}
    }

    .debian & a.create {
      color: $debian-red-0;
      &:hover { color: $debian-red-1;}
    }
    .centos & a.create {
      color: $centos-orange-0;
      &:hover { color: $centos-orange-1;}
    }
    .archlinux & a.create {
      color: $archlinux-black-0;
      &:hover { color: $archlinux-black-1;}
    }
    .opensuse & a.create {
      color: $opensuse-green1-0;
      &:hover { color: $opensuse-green1-1;}
    }

    .ubuntu & {background-image: linear-gradient(90deg, $ubuntu-orange-0 0%, $ubuntu-orange-1 100%);}
    .debian & {background-image: linear-gradient(90deg, $debian-red-0 0%, $debian-red-1 100%);}
    .centos & {background-image: linear-gradient(90deg, $centos-orange-0 0%, $centos-orange-1 100%);}
    .archlinux & {background-image: linear-gradient(90deg, $archlinux-black-0 0%, $archlinux-black-1 100%);}
    .opensuse & {background-image: linear-gradient(90deg, $opensuse-green1-0 0%, $opensuse-green1-1 100%);}
}


.pricing-features {
  .ubuntu & {
    .features .icon {fill: $ubuntu-orange-0;}
  }
  .debian & {
    .features .icon {fill: $debian-red-0;}
  }
  .centos & {
    .features .icon {fill: $centos-violet-1;}
  }
  .archlinux & {
    .features .icon {fill: $archlinux-blue-0;}
  }
  .opensuse & {
    .features .icon {fill: $opensuse-green-0;}
  }
  .laravel & { .features svg {fill: lighten($laravel,5%);}}
  .ghost & { .features svg {fill: lighten($ghost,5%);}}
  .fourimages & { .features svg {fill: lighten($fourimages,5%);}}
  .apache & { .features svg {fill: lighten($apache,55%);}}
  .cakephp & { .features svg {fill: lighten($cakephp,5%);}}
  .directadmin & { .features svg {fill: lighten($directadmin,5%);}}
  .jboss & { .features svg {fill: lighten($jboss,5%);}}
  .grails & { .features svg {fill: lighten($grails,5%);}}
  .ispconfig & { .features svg {fill: lighten($ispconfig,5%);}}
  .rails & { .features svg {fill: lighten($rails,5%);}}
  .vbulletin & { .features svg {fill: lighten($vbulletin,5%);}}
  .cs-cart & { .features svg {fill: lighten($cs-cart,5%);}}
  .drupal & { .features svg {fill: lighten($drupal,5%);}}
  .hhvm & { .features svg {fill: lighten($hhvm,5%);}}
  .glassfish & { .features svg {fill: lighten($glassfish,5%);}}
  .elastix & { .features svg {fill: lighten($elastix,5%);}}
  .elasticsearch & { .features svg {fill: lighten($elasticsearch,5%);}}
  .magento & { .features svg {fill: lighten($magento,5%);}}
  .bugzilla & { .features svg {fill: lighten($bugzilla,5%);}}
  .woocommerce & { .features svg {fill: lighten($woocommerce,5%);}}
  .chamilo & { .features svg {fill: lighten($chamilo,5%);}}
  .zencart & { .features svg {fill: lighten($zencart,5%);}}
  .typesettercms & { .features svg {fill: lighten($typesettercms,5%);}}
  .concrete5 & { .features svg {fill: lighten($concrete5,5%);}}
  .craftcms & { .features svg {fill: lighten($craftcms,5%);}}
  .git & { .features svg {fill: lighten($git,5%);}}
  .meteor & { .features svg {fill: lighten($meteor,5%);}}
  .python & { .features svg {fill: lighten($python,5%);}}
  .subversion & { .features svg {fill: lighten($subversion,5%);}}
  .odoo & { .features svg {fill: lighten($odoo,5%);}}
  .mongodb & { .features svg {fill: lighten($mongodb,5%);}}
  .wordpress & { .features svg {fill: lighten($wordpress,5%);}}
  .cpanel & { .features svg {fill: lighten($cpanel,5%);}}
  .webmin & { .features svg {fill: lighten($webmin,5%);}}
  .codeigniter & { .features svg {fill: lighten($codeigniter,5%);}}
  .civicrm & { .features svg {fill: lighten($civicrm,5%);}}
  .virtualmin & { .features svg {fill: lighten($virtualmin,5%);}}
  .mysql & { .features svg {fill: lighten($mysql,5%);}}
  .mariadb & { .features svg {fill: lighten($mariadb,5%);}}
  .tomcat & { .features svg {fill: lighten($tomcat,5%);}}
  .teamspeak & { .features svg {fill: lighten($teamspeak,5%);}}
  .nodejs & { .features svg {fill: lighten($nodejs,5%);}}
  .suitecrm & { .features svg {fill: lighten($suitecrm,5%);}}
  .sugarcrm & { .features svg {fill: lighten($sugarcrm,5%);}}
  .cloudflare & { .features svg {fill: lighten($cloudflare,5%);}}
  .django & { .features svg {fill: lighten($django,5%);}}
  .owncloud & { .features svg {fill: lighten($owncloud,5%);}}
  .nextcloud & { .features svg {fill: lighten($nextcloud,5%);}}
  .pbx & { .features svg {fill: lighten($pbx,5%);}}
  .roundcube & { .features svg {fill: lighten($roundcube,5%);}}
  .postgresql & { .features svg {fill: lighten($postgresql,5%);}}
  .redmine & { .features svg {fill: lighten($redmine,5%);}}
  .prestashop & { .features svg {fill: lighten($prestashop,5%);}}
  .nginx & { .features svg {fill: lighten($nginx,5%);}}
  .redis & { .features svg {fill: lighten($redis,5%);}}
  .phpmyadmin & { .features svg {fill: lighten($phpmyadmin,5%);}}
  .opencart & { .features svg {fill: lighten($opencart,5%);}}
  .vestacp & { .features svg {fill: lighten($vestacp,5%);}}
  .lemp & { .features svg {fill: lighten($lemp,5%);}}
  .lamp & { .features svg {fill: lighten($lamp,5%);}}
  .joomla & { .features svg {fill: lighten($joomla,5%);}}
  .metasfresh & { .features svg {fill: lighten($metasfresh,5%);}}
  .vtiger & { .features svg {fill: lighten($vtiger,5%);}}
  .php & { .features svg {fill: lighten($php,5%);}}

}

.quote-border-line {
    .ubuntu & {background-image: linear-gradient(90deg, $ubuntu-orange-0 0%, $ubuntu-aubergine-0 100%);}
    .debian & {background-image: linear-gradient(90deg, $debian-red-0 0%, $debian-violet-1 100%);}
    .centos & {background-image: linear-gradient(90deg, $centos-orange-0 0%, $centos-violet-1 100%);}
    .archlinux & {background-image: linear-gradient(90deg, $archlinux-blue-0 0%, $archlinux-blue-1 100%);}
    .opensuse & {background-image: linear-gradient(90deg, $opensuse-green-0 0%, $opensuse-green-1 100%);}
}

