/* cabin-regular - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/cabin-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Cabin'), local('Cabin-Regular'),
       url('../fonts/cabin-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin-regular.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* cabin-italic - latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/cabin-v14-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin Italic'), local('Cabin-Italic'),
       url('../fonts/cabin-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin-italic.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* cabin-500 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/cabin-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Cabin Medium'), local('Cabin-Medium'),
       url('../fonts/cabin-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin-500.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* cabin-500italic - latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/cabin-v14-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin Medium Italic'), local('Cabin-MediumItalic'),
       url('../fonts/cabin-v14-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin-500italic.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* cabin-600 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/cabin-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Cabin SemiBold'), local('Cabin-SemiBold'),
       url('../fonts/cabin-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin-600.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* cabin-600italic - latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/cabin-v14-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin SemiBold Italic'), local('Cabin-SemiBoldItalic'),
       url('../fonts/cabin-v14-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin-600italic.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* cabin-700 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/cabin-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Cabin Bold'), local('Cabin-Bold'),
       url('../fonts/cabin-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin-700.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* cabin-700italic - latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/cabin-v14-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin Bold Italic'), local('Cabin-BoldItalic'),
       url('../fonts/cabin-v14-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin-700italic.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/merriweather-v21-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Light'), local('Merriweather-Light'),
       url('../fonts/merriweather-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/merriweather-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/merriweather-v21-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/merriweather-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/merriweather-v21-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-300italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/merriweather-v21-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'),
       url('../fonts/merriweather-v21-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/merriweather-v21-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/merriweather-v21-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/merriweather-v21-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/merriweather-v21-latin-300italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/merriweather-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Regular'), local('Merriweather-Regular'),
       url('../fonts/merriweather-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/merriweather-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/merriweather-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/merriweather-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/merriweather-v21-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/merriweather-v21-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Italic'), local('Merriweather-Italic'),
       url('../fonts/merriweather-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/merriweather-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/merriweather-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/merriweather-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/merriweather-v21-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/merriweather-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Bold'), local('Merriweather-Bold'),
       url('../fonts/merriweather-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/merriweather-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/merriweather-v21-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/merriweather-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/merriweather-v21-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}

