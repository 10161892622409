
body {
  font-size: 1.125rem;
  font-family: $sans;
  color: $oc-gray-7;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    // color: $oc-gray-9;
}

.font-sans {
   font-family: $sans;
}

.font-serif {
   font-family: $serif;
}

a {
  outline: none;

  &:focus, &.active {
    outline: none;
  }
}

.container {
  &:not(.no-override) {
    @media (min-width: 1200px) {
      max-width: 1070px;
    }
  }

  @media(min-width: 992px) {
    position: relative;
  }
}


// anchor links

a {
  color: #3e70ff;

  @include transition(all .2s);

  &:hover {
    color: #459CE7;
    text-decoration: none;
  }
}


// pagination

.page-item {
  &.active {
    .page-link {
      background-color: #4e7396;
    }
  }
}

.page-link {
  color: #5F8AB0;
  padding: 0.5rem 0.9rem;

  @include transition(all .1s linear);
}


// table responsive
.table-responsive {
  @media(max-width: 991px) {
    th,
    td {
      white-space: nowrap;
    }
  }
}

