//-----------------------------
//  Yamm Styles
//-----------------------------

.yamm {

  // reset positions
  .nav, .collapse, .dropup, .dropdown {
    position: static;
  }

  // propagate menu position under container for fw navbars
  .container {
    position: relative;
  }

  // by default aligns menu to left
  .dropdown-menu {
    left: auto;
  }

  // Content with padding
  .yamm-content {
    // padding: 20px 30px;
  }

  // Fullwidth menu
  .dropdown.yamm-fw .dropdown-menu {
    left: 0; right: 0;
  }

}

@media (min-width: 992px) {

  .dropdown-menu::before {
    border-bottom: 9px solid rgba(0, 0, 0, 0.2);
    border-left: 9px solid rgba(0, 0, 0, 0);
    border-right: 9px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    left: 32px;
    position: absolute;
    top: -8px;
}
  .dropdown-menu::after {
    border-bottom: 8px solid #FFFFFF;
    border-left: 9px solid rgba(0, 0, 0, 0);
    border-right: 9px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    left: 32px;
    position: absolute;
    top: -7px;
  }

}
