.faq {
  background: $oc-gray-0;
  border-top: 1px solid $oc-gray-2;
  border-bottom: 1px solid $oc-gray-2;


  .panel {
    padding: 0 30px;
    border: 2px solid $oc-gray-2;
    margin-bottom: 15px;
    background: #fff;

    &.open {
      border-color: $oc-gray-3;
      background: $oc-gray-0;
    }

    &-body {
      padding: 0 0 15px 0;
      font-size: 0.875rem;
    }
  }

  &-icon {
    margin-right: 10px;
    font-weight: bold;
  }

  &-title {
    padding: 15px 0;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.925rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    .panel.open & {
      color: $oc-gray-8;
    }
  }

}
