.index-price {
  font-size: 28px!important;

  span.price{
    font-size: 36px;
    font-weight: 700;
  }
}

.index-starting-from{
  font-weight: 300;
  font-size: 14px;
}

.index-intro-features {
  margin-top: 60px;
  padding-top: 60px;
  // text-align: center;
  color:  #fff;

  // background-image: linear-gradient(90deg, #2B6280 0%, #2B6280 100%);
  background: linear-gradient(-12deg,$oc-blue-6 50%,$oc-blue-8 80%,$oc-violet-6);

  a {
    color: #fff;
    border-bottom: 1px solid #fff;
    &:hover{
      // color:  #fff;
      }
  }

  @media(max-width: 767px) {
    margin-top: 90px;
  }

  .section-title {

    text-align: center;
    margin-bottom: 60px;

    a {
      color: #fff;
      &:hover{
        // color:  #fff;
      }

      &.read-more {
      text-transform: uppercase;
      font-size: 13px;
      }
    }

    h2, h3 {
      // margin: 0;
      color: #fff;
      font-size: 1.25rem;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
      font-weight: 400;

      @media(max-width: 767px) {
        font-size: 18px;
        line-height: 35px;
      }
    }
    h3 {
      font-weight: 300;
    }

    p {
      margin-top: 17px;
      font-size: 18px;
      // color: #D9DEF5;
    }
  }


  .icon {
    width: 72px;
    height: 72px;
    fill: #fff;
    // padding: 10px;
    // background: $oc-green-1;
    // border-radius: 50%;

    // &.icons8-Checked {
    //   fill: $oc-violet-6;
    //   background: $oc-violet-1;
    // }

    // &.icons8-Last-24-Hours {
    //   fill: $oc-green-6;
    //   background: $oc-green-1;
    // }

    // &.icons8-Data-Backup {
    //   fill: $oc-indigo-6;
    //   background: $oc-indigo-1;
    // }

    // &.icons8-Network {
    //   fill: $oc-yellow-6;
    //   background: $oc-yellow-1;
    // }

    // &.icons8-Paper-Plane {
    //   fill: $oc-red-6;
    //   background: $oc-red-1;
    // }
  }

  h5 {
    margin-bottom: 20px;
    color: #fff;
    text-transform:  uppercase;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.5px;
  }

  .col-md-4 {
    margin-bottom: 60px;
  }

  i {
    display: inline-block;
    font-size: 54px;
    color: #4e94f1;

    &.ion-ios-infinite-outline {
      position: relative;
      top: -10px;
    }

    &.ion-ios-book-outline {
      position: relative;
      top: -10px;
    }
  }

  p {
    @media(max-width: 767px) {
      margin-bottom: 35px;
    }
  }
}


.index-features-files {
  padding-top: 90px;

  .container2 {
    clear: left;
    float: left;
    width: 100%;
    overflow: hidden;
    border-top: 1px solid #e2e9f0;

    .container1 {
      float: left;
      width: 100%;
      position: relative;
      right: 50%;
      background: #f5f7f9;
      border-right: 1px solid #e2e9f0;

      @media(max-width: 991px) {
        right: auto;
      }
    }
  }

  .col-left {
    float: left;
    width: 50%;
    position: relative;
    left: 50%;
    overflow: hidden;
    padding: 78px 50px 0;

    @media(max-width: 991px) {
      width: 100%;
      left: 0;
      padding: 60px 70px 0;
    }

    @media(max-width: 767px) {
      padding: 50px 30px 0;
    }

    .features {
      float: right;
      width: 400px;

      @media(max-width: 991px) {
        float: none;
        width: auto;
      }

      .feature {
        margin-bottom: 30px;
      }

      .image, .info {
        float: left;

        @media(max-width: 767px) {
          float: none;
        }
      }

      .info {
        width: 80%;
        margin-left: 40px;

        @media(max-width: 767px) {
          width: auto;
          margin-left: 0;
          margin-top: 20px;
        }

        strong {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 600;
          color: #444;
          letter-spacing: .5px;
        }

        p {
          color: #657790;
          margin-top: 12px;
          line-height: 24px;
          width: 90%;
        }

        a.underline {
            color: $oc-gray-7;
            border-bottom: 1px solid $oc-gray-7;
            font-size: 0.725rem;
            text-transform: uppercase;
            transition: all 0.2s;
            &:hover {
            color: $oc-blue-7;
            border-bottom: 1px solid $oc-blue-7;
            }
        }
      }
    }
  }

  .col-right {
    float: left;
    width: 50%;
    position: relative;
    left: 50%;
    overflow: hidden;
    padding: 50px 70px 70px;

    @media(max-width: 991px) {
      width: 100%;
      left: 0;
    }

    @media(max-width: 767px) {
      padding: 30px;
    }

    .info {
      width: 340px;

      @media(max-width: 991px) {
        width: auto;
      }
    }

    h3 {
      font-size: 13px;
      margin-top: 20px;
      color: #70A9CD;
      text-transform: uppercase;
      line-height: 17px;
      letter-spacing: 2px;
      font-weight: 600;
    }

    h4 {
      font-size: 27px;
      color: #4B4B4B;
      line-height: 37px;
      font-weight: 300;
      margin-top: 15px;
    }

    p {
      font-size: 15px;
      color: #7B8398;
      line-height: 27px;
      margin-top: 30px;
    }
  }

  .home-logos {
    text-align:   center;
  }
  .home-logos .icon {
     width: 64px;
     height: 64px;
     padding: 10px;
     fill: $oc-green-8;
     background: $oc-green-2;
     border-radius: 50%;
  }

  .home-logos .icon.wordpress {
     fill: $wordpress;
     background: lighten( $wordpress, 50% )
  }
  .home-logos .icon.discourse {
     fill: $discourse;
     background: lighten( $discourse, 55% )
  }
  .home-logos .icon.gitlab {
     fill: $gitlab;
     background: lighten( $gitlab, 38% )
  }
  .home-logos .icon.ghost {
     fill: $ghost;
     background: lighten( $ghost, 38% )
  }
  .home-logos .icon.odoo {
     fill: $odoo;
     background: lighten( $odoo, 45% )
  }

  .home-logos .icon.drupal {
     fill: $drupal;
     background: lighten( $drupal, 50% )
  }
  .home-logos .icon.magento {
     fill: $magento;
     background: lighten( $magento, 35% )
  }
  .home-logos .icon.laravel {
     fill: $laravel;
     background: lighten( $laravel, 35% )
  }
  .home-logos .icon.joomla {
     fill: $joomla;
     background: lighten( $joomla, 35% )
  }

  .home-logos h4{
    line-height: 1 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    font-size: 16px !important;
  }

}


.index-mobile-features {

  .index-intro-hero {
    padding: 30px 0 0;
    text-align: center;

    @media(max-width: 991px) {
      padding-top: 200px;
    }

    @media(max-width: 767px) {
      padding-top: 15px;
    }

    h1, h2 {
      font-size: 1.75rem;
      margin-bottom: 10px;
      letter-spacing: 1.5px;
      font-weight: 500;
      color: $oc-gray-8;

      @media(max-width: 991px) {
        font-size: 20px;
      }

      @media(max-width: 767px) {
        font-size: 18px;
        line-height: 17px;
      }
    }

    h2 {
      letter-spacing: 0.5px;
      font-weight: 300;
      color: $oc-gray-6;

      font-size: 1rem;
      margin-bottom: 25px;
    }
  }

  // margin-top: 130px;
  padding: 60px 0 65px;
  // background-image: -o-linear-gradient(0deg, $oc-violet-5 0%, $oc-violet-8 100%);
  // background-image: -moz-linear-gradient(0deg, $oc-violet-5 0%, $oc-violet-8 100%);
  // background-image: -ms-linear-gradient(0deg, $oc-violet-5 0%, $oc-violet-8 100%);
  // background-image: linear-gradient(90deg, $oc-violet-5 0%, $oc-violet-8 100%);

  @media(max-width: 767px) {
    margin-top: 90px;
  }





  .features {
    // max-width: 750px;
    margin: 0 auto;
    margin-top: 55px;

    .feature {
      float: left;
      width: 33%;
      text-align: left;
      padding: 10px 20px 40px;

      @media(max-width: 767px) {
        width: 100%;
        border-right: none;
         border-bottom: 1px solid $oc-violet-8;

        &:last-child {
          border-bottom: 0;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-right: 1px solid $oc-gray-2;
      }

      .pic {
        min-height: 75px;

        svg {
          fill: $oc-blue-6;
          width: 76px;
          height: 76px;
        }

      }

      p, h3 {
        // font-size: 1.125rem;
        // color: $oc-violet-0;
        font-weight: 400;
        text-align: left;
      }

      h3 {
        text-transform:   uppercase;
        letter-spacing: 1px;
        // color: #fff;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}


.index-clients {
  text-align: center;

  header {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: $oc-gray-2;
      top: 6px;
    }

    h4 {
      position: relative;
      top: -8px;
      padding: 0 40px;
      background: #fff;
      color:  $oc-gray-7;
      display: inline-block;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 1.8px;
      word-spacing: 1.6px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .img-responsive {
    margin: 0 auto;
  }

  @media (max-width: 767.98px) {
    img {
      margin-bottom: 30px;
      max-width: 200px;
    }

  }
}



.index-affiliate {
  // margin-top: 110px;
  // background: url('#{$imagesPath}/graphics/spacial-bg.jpg') no-repeat center center;
  // background-size: cover;
  // background: linear-gradient(-12deg,$oc-blue-6 50%,$oc-blue-8 80%,$oc-violet-6);

    position: relative;
    background-color:  $oc-blue-8;
    background-image: url(../images/hand-shake.png);
    background-position: 98% -60px,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;

  text-align: center;
  padding: 70px 0;

  @media(max-width: 991px) {
    padding: 90px 20px;
  }

  @media(max-width: 767px) {
    padding: 70px 20px;
  }

  h2 {
    color: #fff;
    font-size: 34px;
    letter-spacing: .5px;

    @media(max-width: 767px) {
      font-size: 28px;
    }
  }

  p {
    color: #fff;
    width: 42%;
    margin: 0 auto;
    margin-top: 40px;
    line-height: 27px;

    @media(max-width: 991px) {
      width: 80%;
    }

    @media(max-width: 767px) {
      width: auto;
    }
  }

}



.feature .icon {
  width: 36px;
  height: 36px;
  padding:0;
  background: transparent;
  border-radius: 0;
}


