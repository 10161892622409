.index-testimonials {
  padding-top: 70px;
 
    position: relative;
    background-color: $oc-blue-8;
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;


  h3 {
    text-align: center;
    margin-bottom: 70px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;

    @media(max-width: 767px) {
      margin-bottom: 50px;
    }
  }

  .quote {
    position: relative;


    .quote-container {
      background: #ccc;

      &:after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background: #fff;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -9px;
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background: transparent;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -10px;
        border-radius: 100px;
        box-shadow: 0 4px 20px rgba(62,57,107,0.08), 0 2px 9px 0 rgba(62,57,107,0.07), 0 4px 18px rgba(62,57,107,0.07);
        z-index: -1;
      }
    }


    @media(max-width: 767px) {
      margin-bottom: 30px;
    }

    .quote-mark {
      font-size: 63px;
      color: $oc-green-1;
      float: left;
      font-family: Georgia;
      position: relative;
      top: -20px;
      margin-right: 10px;
    }

    p {
      width: 80%;
      float: left;
      font-size: 16px;
      line-height: 28px;
      color: #fff;
    }

    .author {
      float: left;
      margin-left: 28px;
      margin-top: 9px;

      img {
        float: left;
        border-radius: 100px;
        max-width: 55px;
      }

      .name {

        &:before {content:'-'}

        float: left;
        margin-left: 12px;
        margin-top: 0;
        color: $oc-green-2;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 1px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}