$wordpress: #21759B;
$drupal: #0678BE;
$magento: #EE672F;
$laravel: #E74430;
$joomla: #5091CD;
$odoo: #a24689;
$gitlab: #E24329;
$ghost: #718087;
$discourse: #444;

@import 'stack_pages';
@import 'testimonials';
@import 'footer';
@import 'multicolumn_dropdown';
@import 'pricing';
@import 'faq';
@import 'stars_rating';
@import 'ekko-lightbox';
@import 'cookieconsent';

// pages
@import 'pages';
@import 'index';
@import 'policies';

//fixed mobile  nav
@media (max-width: 991px) {
  body {
  padding-top:63px;
   .navbar {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
   }
  }
  .demo-wrapper {
    display: none;
  }
}

@media (min-width: 992px) {
  .no_navigation {
   .navbar-brand {
     padding: 25px 0;
     margin: 0 auto;
   }
  }
  .demo-wrapper {
    display: inline-block;
  }
}

.tooltip-wide .tooltip {min-width:300px;}

.navbar-blue {
  .navbar{
    background-color: $oc-blue-8!important;
  }
}

.navbar-red {
  .navbar{
    background-color: $oc-red-6!important;
  }
}

@media (min-width: 1200px) {

.navbar-blue .nav-item .nav-link--rounded {
    border: 1px solid #fff;
    color: #fff;
  }
}

.card-deck > .card-deck {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
}

.violet-gradient {
  background-image: linear-gradient(90deg, $oc-violet-6 0%, $oc-violet-9 100%);
}

.red-gradient {
  background-image: linear-gradient(90deg, $oc-red-6 0%, $oc-red-6 100%);
}

.blue-gradient {
  background-image: linear-gradient(90deg, $oc-blue-6 0%, $oc-blue-9 100%);
}
.blue-light-gradient {
  background-image: linear-gradient(90deg, $oc-blue-1 0%, $oc-blue-2 100%);
}

.green-gradient {
  background-image: linear-gradient(90deg, $oc-green-6 0%, $oc-green-8 100%);
}
.h-header-gradient {
  background-image: linear-gradient(90deg, $oc-blue-6 0%, $oc-violet-9 100%);
}

.color-white {
  color: #fff;
}

.font-normal {
  font-weight: 400;
}

.bg-gray-1 {
  background-color: $oc-gray-1;
}

.z-index-1 {
  z-index: 1;
}

.relative {
  position: relative;
}

.text-shadow-none {
  text-shadow: none;
}

.font-normal {
  font-style: normal!important;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-0 {
  letter-spacing: 0;
}

.no-opacity {
  opacity: 1!important;
}

.b-gray-2 {
  border: 1px solid $oc-gray-2;
}

.bt-gray2-1{
  border-top: 1px solid $oc-gray-2;
}

.bb-gray2-1{
  border-bottom: 1px solid $oc-gray-2;
}

.black-link {
  color: $oc-gray-8;
  border-bottom: 1px solid $oc-gray-8;
  &:hover {
    color: $oc-gray-6;
    border-bottom: 1px solid $oc-gray-6;
  }
}

.white-link {
  color: #fff;
  border-bottom: 1px solid #fff;
  &:hover {
    color: #fafafa;
    border-bottom: 1px solid #fafafa;
  }
}

.btc-sucess {
  border-top-color: #28a745!important;
}
.bbc-sucess {
  border-bottom-color: #28a745!important;
}

.vps-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: url(../images/vps-hosting.jpg);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
	  background-size: cover;
    padding: 30px 0;
}

.nvme-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: url(../images/nvme-hosting.jpg);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}

.ha-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: url(../images/high-availability-hosting.jpg);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}


.managed-cloud-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: linear-gradient( rgba(0,0,0, .5), rgba(0,0,0, .5)), url(../images/cloud-hosting-prop.jpg);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}

.cloud-vps-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: linear-gradient( rgba(0,0,0, .5), rgba(0,0,0, .5)), url(../images/cloud-vps-hosting.jpg);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}
.winter-sale-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: linear-gradient( rgba(0,0,0, .7), rgba(0,0,0, .3)), url(../images/winter-sale-bg4.jpg);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}
.anniversary-sale {
    position: relative;
    background-color: $oc-blue-8;
    background-image: linear-gradient( rgba(0,0,0, .7), rgba(0,0,0, .3)), url(../images/20th-anniversary-sale-bg.png);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}
.hostadvice-specials-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: linear-gradient( rgba(7, 69, 135, .7), rgba(7, 69, 135, .3)), url(../images/hostadvice.jpg);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}
.winter-sale-hosting-hero2 {
    position: relative;
    background-color: $oc-blue-8;
    background-image: linear-gradient( rgba(0,0,0, .7), rgba(0,0,0, .3)), url(../images/winter-sale-bg8.jpg);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}

.linux-shared-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: url(../images/linux-shared-prop.jpg), linear-gradient(90deg, $oc-indigo-6 0%, $oc-indigo-9 100%);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}

.web-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: url(../images/web-hosting-prop.jpg), linear-gradient(90deg, $oc-indigo-6 0%, $oc-indigo-9 100%);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;
}

.dedicated-hosting-hero {
    position: relative;
    background-color: $oc-blue-8;
    background-image: url(../images/rocket-2100-700.svg), linear-gradient(90deg, $oc-indigo-6 0%, $oc-indigo-9 100%);
    background-position: center  bottom,center center;
    background-size: cover;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    padding: 30px 0;
}


.index-hero{
    position: relative;
    background-color: $oc-blue-8;
    background-image: url(../images/linux-cloud-hosting.jpg), linear-gradient(90deg, $oc-blue-8 0%, $oc-blue-9 100%);
    background-position: right center,center center;
    background-repeat: no-repeat,no-repeat;
    overflow: hidden;
    background-size: cover;
    padding: 30px 0;

    a{
      fill: $oc-blue-5;
    }
  // a.btn {
  //   background: $oc-blue-6;
  //   color: #fff;
  //   display: inline-block;
  //   margin-top: 0;
  //   padding: 20px 40px;
  //   font-weight: 600;
  //   text-transform: uppercase;
  //   letter-spacing: 1px;
  //   border-radius: 3px;
  //   font-size: 13px;
  //   text-decoration: none;

  //   @include transition(all .3s);

  //   @media(max-width: 767px) {
  //     margin-top: 50px;
  //   }

  //   &:hover {
  //     background: $oc-blue-7;
  //     box-shadow: 0 4px 18px 3px rgba(0, 0, 0, 0.42);
  //   }
  // }
}



// .vps-testimonials {
//     background-image: linear-gradient(90deg, $oc-orange-7 0, $oc-orange-8 100%);
//     h2, h3 {
//       color: #fff;
//       text-shadow: none;
//     }
// }

//////////////

.color-block {
  background: #fff;
  text-align: center;
  padding: 60px 0;

    h3, h2,h1 {
      color: $oc-gray-8;
      font-size: 1.25rem;
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.25px
    }

    p {
      margin-top: 6px;
      color: $oc-gray-6;
    }
}

.color-block-blue {
    background: $oc-blue-5;
    h3, h2,h1 {
      color: #fff;
    }
    p {
      color: $oc-blue-2;
  }
}

.color-block-green {
    background: $oc-green-5;
    p {
      color: $oc-green-2;
  }
}

.color-block-indigo {
    background: $oc-indigo-5;
    p, h3 {
      color: #fff;
  }
}

////////////


////////////




.quote {

  &-container {
    background: #fff;
    box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
    min-height: 275px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-radius: 5px;

    &:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background: transparent;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -10px;
        border-radius: 100px;
        box-shadow: 0 4px 20px rgba(62, 57, 107, 0.08), 0 2px 9px 0 rgba(62, 57, 107, 0.07), 0 4px 18px rgba(62,     57, 107, 0.07);
        z-index: -1
    }
    &:after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background: #fff;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -9px;
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }
  }

  &-border-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &-content {
    letter-spacing: -0.5px;
    font-size: 16px;

    p {
      max-height: 210px;
      overflow: auto;
    }
  }

  &-author {
    width: 200px;
  }

  &-author-info {
    width: 150px;
    float: left;
    // color: #fff;
  }

  &-author-img {
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 100px;
    float: left;
    margin-right: 8px;
  }
}


////
.page-header.wordpress {
  .stack-image {
    opacity: 0.25;
    position: absolute;
    width: 50%;
    height: 50%;
    z-index: 0;
    top: 50px;
    right: 0;

    svg {
      fill: #fff
    }
  }
}
////

.btn-white {
      display: inline-block;
      vertical-align: top;
      border-radius: 5px;
      background-image: linear-gradient(80deg, #fff, $oc-gray-0);

      font-size: 14px;
      text-transform: uppercase;
      color: $oc-gray-8;
      line-height: 18px;
      padding: 15px 30px;
      font-weight: 700;
      text-decoration: none;
      letter-spacing: .5px;

      @include transition(all .2s linear);

      &:hover {
        background-image: linear-gradient(80deg, $oc-gray-0, $oc-gray-1);
        color: $oc-gray-6;
      }
}



.vps-text {
  background: $oc-blue-8;
  color: #fff;
  border-top: 1px solid $oc-gray-2;
}



.index-tabs {
  background: $oc-gray-1;
}

.nav-tabs .nav-link {
  // background: #ecf5fe;
}

@media (min-width: 600px) {
    .nav-tabs .nav-link {
      min-width: 200px;
    }
}


.tabs-section {
  border-bottom: 1px solid $oc-gray-2;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover{
    border: none;
    border-bottom: 2px solid $oc-blue-8;
    color:  #fff;
    margin-bottom: -1px;
    background: $oc-blue-8;
}


.nav-tabs .nav-link, .nav-tabs .nav-link:active, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border: 1px solid transparent;
    color:  $oc-gray-8;
    font-size: 15px;
    letter-spacing: 0.5px
}


.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs {

    border-bottom: 2px solid $oc-blue-8;
}

.center-pills {
    display: inline-block
}


.awards-box {
  min-height: 200px;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

.item {
    margin: 0 10px 1px 10px
}


.oci-md-4 {
    flex: 0 0 23%;
    max-width: 23%;
}
