.before-footer {
  background: $oc-blue-8;
  color: #fff;
  padding: 30px 0;

  .review-text {
    a{
      color: #fff;
      border-bottom: 1px solid $oc-blue-3;
      @include transition(all .2s linear);
      &:hover {
        border-bottom-color: #fff;
      }
    }
  }

  .get-started {
    font-weight: 400;
    margin-top: 30px;
    line-height: 42px;


    a {
      color: $oc-blue-8;
      margin-left: 15px;

    @media(max-width: 767px) {
      display: block;
    }

      &:hover {
        color: $oc-blue-6;
      }

      @media(max-width: 991px) {
        margin-top: 15px;
        margin-left: 0;
      }
    }
  }
}


// footer big menu
.footer-big-menu {
  // margin-top: 30px;
  padding: 30px 0 30px;
  background: $oc-gray-1;
  position: relative;
  border-top: 1px solid #e2e9f0;

  .cta {
    .cta-title {
      color: $oc-gray-7;
      font-size: 1.6rem;
      margin-bottom: 40px;
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);

      @media(max-width: 767px) {
        font-size: 1.3rem;
      }
    }

    a {
      font-size: 12px;
    }

    .btn-outline {
      text-transform: uppercase;
      border: 2px solid #91bbe0;
      font-weight: 600;
      padding: 12px 23px;
      color: #b5dcff;
      border-radius: 0.25rem;
      letter-spacing: 0.5px;
      display: inline-block;

      @include transition(all .2s);

      &:hover {
        border-color: #fff;
        color: #fff;
      }

      @media (max-width: 575px) {
        margin-top: 20px;
      }
    }
  }

  .menu {
    margin-top: 40px;

    @media (max-width: 767px) {
      margin-top: 60px;
      text-align: center;
    }

    .col-md-3 {
      @media(max-width: 991px) {
        margin-bottom: 30px;
      }
    }

    .menu-title {
      color: $oc-gray-8;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.5px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .menu-link {
      color: $oc-gray-6;
      display: block;
      margin-bottom: 2px;
      font-size: 14px;

      @include transition(all .2s);

      &:hover {
        color: $oc-gray-7;
      }
    }

    .social-link {
      width: 32px;
      height: 32px;
      display: inline-block;
      position: relative;
      border-radius: 30px;
      color: #dfebf5;
      border: 2px solid #c6d8e8;
      margin-right: 6px;

      @include transition(all .2s);

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #fff;
        border-color: #fff;
      }

      i {
        position: absolute;
        font-size: 16px;
        left: 8px;
        top: 2px;

        &.ion-social-facebook {
          left: 11px;
        }

        &.ion-social-whatsapp {
          left: 7px;
        }
      }
    }
  }

  .bottom {
    color: $oc-gray-7;
    text-align: center;
    font-size: 13px;

    .privacy-shield {

      margin-top: 20px;

      @media(max-width: 767px) {

        margin: 10px auto 0;
        text-align:center;
      }
    }

    a {
      color: $oc-gray-7;
      // margin-left: 5px;
      display: inline-block;
      border-bottom: 1px solid $oc-gray-7;


      @include transition(all .2s);

      &:hover {
        color: $oc-gray-6;
        border-bottom: 1px solid $oc-gray-6;
      }
    }
  }
}